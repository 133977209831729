<template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">              
              <form class="form-sample">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Currency</label>
                           <multiselect v-model="$v.typeform.selected_currency.$model" :options="currencies" :multiple="false"
                                :close-on-select="true" label="currency_code"
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select currency"
                                 :preselect-first="true" :showLabels="false"></multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">                      
                      <div class="col-sm-12">
                        <label class="col-form-label">OPD New Case Fee</label>
                        <input type="text" class="form-control" v-model="$v.typeform.new_case_fee.$model" placeholder="New Case Fee"/>                        
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">OPD Old Case Fee</label>
                        <input type="text" class="form-control" v-model="$v.typeform.old_case_fee.$model" placeholder="Old Case Fee"/>                        
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <div class="col-sm-12">
                        <label class="col-form-label">Case Expire in</label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="$v.typeform.case_expire_in.$model" placeholder="Case Expire In"/>
                          <div class="input-group-append">
                            <span class="input-group-text">Days</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Timezone From</label>
                           <multiselect v-model="$v.typeform.selected_time_zone_from.$model" :options="timezonefromlist" :multiple="false"
                                :close-on-select="true" label='name'
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select timezone of"
                                 :preselect-first="false" :showLabels="false"></multiselect>
                      </div>
                    </div>
                  </div>
                  <div v-if="typeform.selected_time_zone_from.id == 1" class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Timezone</label>
                           <multiselect v-model="$v.typeform.selected_time_zone.$model" :options="timezonelist" :multiple="false"
                                :close-on-select="true" label=''
                                :clear-on-select="false" :preserve-search="true" placeholder="Please select timezone"
                                 :preselect-first="true" :showLabels="false"></multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row prescription_background">
                    <h3 class="page-title mb-3">Prescription Background</h3>
                    <div class="row">
                        <div class="col-lg-4 imagec">
                          <img :src="prescription_background_preview" :class="{ selected : prescription_background!='' }" class="img-fluid w-100"/>
                          <div class="img-top-right" v-if="prescription_background">
                            <button type="button" @click="deletePrescriptionBackground()" class="btn btn-outline-danger btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                          </div>
                          <div class="img-centered" v-if="img_progress">
                            <b-spinner label=""></b-spinner>
                          </div>
                        </div>
                        <div class="col-lg-6 mt-4" v-if="prescription_background == ''">
                            <div class="row">
                                <label for="prescription_background">Select Image</label>
                            </div>
                            <div class="row mt-2">
                                <input type="file" id="prescription_background" accept="image/*" ref="image" v-on:change="handleFileUpload">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <b-spinner v-if="is_btn_spinner" label=""></b-spinner>
                    <button v-else type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Submit</button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import string from "../../constant/strings.js"
import Multiselect from 'vue-multiselect';
import {set_user_settings } from '../../helper/helper';

export default {
    name:'settings',
    title: string.PAGE_TITLE_SETTINGS,
    components: {
      NavBar,
      Footer,
      Multiselect,
      SideBar
    },
    data() {

    return {
      prescription_background: '',
      prescription_background_preview: 'https://dummyimage.com/1171x1600/000/fff',
      selected_image: '',

      typeform:{
        new_case_fee:'',
        old_case_fee:'',
        case_expire_in:'',
        selected_currency:{},
        selected_time_zone:{},
        selected_time_zone_from:{},
        },
      is_btn_spinner:false,
      img_progress:false,
      currencies:[],
      timezonelist:[],
      timezonefromlist:[{'id':0, 'name':'Local Machine'}, {'id':1, 'name':'Select Own'}],
    }
    },
    validations: {
      typeform: {
        new_case_fee:{required,numeric},
        old_case_fee:{required,numeric},
        case_expire_in:{required,numeric},
        selected_currency:{required},
        selected_time_zone:{required},
        selected_time_zone_from:{required},
      },
    },
  mounted(){
    this.getSettings();
    this.getCurrencies_();
    this.getTimeZoneList();
  },
  methods:{
    ...mapActions('hospital',["getAddEditSettingsData","getSettingsData", "updatePrescriptionBackground", "deletePrescriptionBackgroundData", 'getCurrencies', 'getTimeZoneListData']),

    getWebSettings() {
        this.getWebSettingsData().then((response) => {
        })
    },

    submitForm(){
      var bodyFormData = new FormData();      
      bodyFormData.append('fee_new_opd', this.typeform.new_case_fee);
      bodyFormData.append('fee_old_opd', this.typeform.old_case_fee);
      bodyFormData.append('opd_expire_time', this.typeform.case_expire_in);
      bodyFormData.append('currency_id', this.typeform.selected_currency.id);
      bodyFormData.append('time_zone', this.typeform.selected_time_zone);
      bodyFormData.append('time_zone_from', this.typeform.selected_time_zone_from.id);
      this.is_btn_spinner = true;

      this.getAddEditSettingsData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
          this.is_btn_spinner = false;
          set_user_settings(response.data.currency)
          this.$toasted.success(response.message, {duration: 2000,});
        } else{
          this.is_btn_spinner = false;
          this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },

    getSettings(){
      this.is_btn_spinner = true;
      this.getSettingsData().then((response) => {
        this.is_btn_spinner = false;
        if (response.response_code == 200) {
          this.typeform.new_case_fee = response.data.fee_new_opd;
          this.typeform.old_case_fee = response.data.fee_old_opd;
          this.typeform.case_expire_in = response.data.opd_expire_time;
          this.prescription_background = response.data.prescription_background;
          this.prescription_background_preview = response.data.prescription_background;
          this.typeform.selected_currency = response.data.currency;

          this.updateTimeZoneFrom(response.data.time_zone_from)
          this.typeform.selected_time_zone = response.data.time_zone
        }else if (response.response_code != 104 || response.response_code == 103){
          this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },
    handleFileUpload(e) {
      this.selected_image = "";
      const file = e.target.files[0];
      this.prescription_background = file;
      this.prescription_background_preview = URL.createObjectURL(file);

      this.img_progress = true;
      var bodyFormData = new FormData();
      bodyFormData.append('prescription_background', file);
      this.updatePrescriptionBackground(bodyFormData).then((response) => {
        this.img_progress = false;
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
          } else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
    },
    selectFromGallery(img){
      this.selected_image = img;
      this.prescription_background = '';
      this.prescription_background_preview = "https://dummyimage.com/1171x1600/000/fff";
    },
    deletePrescriptionBackground() {
      this.img_progress = true;
      this.deletePrescriptionBackgroundData().then((response) => {
        this.img_progress = false;
        if (response.response_code == 200) {
            this.selected_image = '';
            this.prescription_background = '';
            this.prescription_background_preview = "https://dummyimage.com/1171x1600/000/fff";
            this.$toasted.success(response.message, {duration: 2000,});
        } else{
          this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },
    getCurrencies_(){
      console.log("Adasdad")
      var bodyFormData = new FormData();
      bodyFormData.append('filter_status', 'Active');
      this.getCurrencies(bodyFormData).then((response) => {
        this.is_btn_spinner = false;
        if (response.response_code == 200) {
            this.currencies = response.data;
        } else if (response.response_code != 104 || response.response_code == 103) {
          this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },

    getTimeZoneList(){
        var bodyFormData = new FormData();
        bodyFormData.append('search_text', '');
        this.getTimeZoneListData(bodyFormData).then((response) => {
        if (response.response_code == 200) {
            this.timezonelist = response.data
        }
      });
    },

    updateTimeZoneFrom(time_zone_from){
        if(time_zone_from == "0") {
            this.typeform.selected_time_zone_from = this.timezonefromlist[0]
        } else {
            this.typeform.selected_time_zone_from = this.timezonefromlist[1]
        }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
